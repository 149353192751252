import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-292f07e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "landingWrapper" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_NewHeaderSection = _resolveComponent("NewHeaderSection")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      class: _normalizeClass(["Navbar", [{ scrolled: $setup.scrollPosition > 50 && $setup.windowSize >= 995 }]])
    }, null, 8, ["class"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NewHeaderSection),
      _createVNode(_component_FooterSection)
    ])
  ]))
}