
import NavBar from "@/components/NavBar.vue";
import HeaderSection from "@/components/HeaderSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import FeaturesSection from "@/components/FeaturesSection.vue";
import DarkBar from "@/components/DarkBar.vue";
import SignUpPlan from "@/components/Auth/SignUpPlan.vue";
import VideoSection from "@/components/VideoSection.vue";
import BottomSection from "@/components/BottomSection.vue";

import { ref, onMounted, onBeforeMount } from "vue";

export default {
  name: "LearnMorePage",
  components: {
    NavBar,
    HeaderSection,
    FooterSection,
    DarkBar,
    FeaturesSection,
    SignUpPlan,
    VideoSection,
    BottomSection,
  },
  setup() {
    const windowSize = ref(0);
    const scrollPosition = ref(0);

    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    return {
      scrollPosition,
      updateScroll,
      windowSize,
    };
  },
};
