
import { Routes } from "@/types/Routes";
import { ref, onMounted, onBeforeMount } from "vue";
import _download from "./getDownload";
import { isMobile as getIsMobile } from "./Auth/isMobile";

export default {
  name: "NewHeaderSection",
  components: {},
  setup() {
    const scrollPosition = ref(0);
    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    const OSName = ref("Unknown OS");
    const isMobile = getIsMobile();

    console.log(isMobile);

    if (navigator.userAgent.indexOf("Win") != -1) OSName.value = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName.value = "MacOS";

    const windowSize = ref(window.innerWidth);

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    const download = async () => {
      await _download(OSName.value);
    };

    return {
      scrollPosition,
      updateScroll,
      Routes,
      windowSize,
      download,
      OSName,
      isMobile,
    };
  },
};
