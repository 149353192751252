import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import EnterprisePage from "../views/EnterprisePage.vue";
import SignInPage from "../views/auth/SignInPage.vue";
import SignUpPage from "../views/auth/SignUpPage.vue";
import SignUpPlan from "../views/auth/SignUpPlan.vue";
import DownloadPage from "../views/DownloadPage.vue";
import SignUpVerify from "../views/auth/SignUpVerify.vue";
import CookiesPage from "../views/CookiesPage.vue";
import PrivacyPage from "../views/PrivacyPage.vue";
import ReleaseNotesPage from "../views/ReleaseNotesPage.vue";
import TosPage from "../views/TosPage.vue";
import NotFound from "../views/NotFound.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import EmailActionHandler from "../views/auth/EmailActionHandler.vue";
import SignUpWelcome from "../views/auth/SignUpWelcome.vue";
import SignUpThis from "../views/auth/SignUpThis.vue";
import SignUpRemote from "../views/auth/SignUpRemote.vue";
import SignUpComplete from "../views/auth/SignUpComplete.vue";
import RedirectPage from "../views/RedirectPage.vue";
import { auth } from "../firebase";
import { Routes } from "@/types/Routes";
import LearnMorePage from "@/views/LearnMorePage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: Routes.LANDING,
    name: "landing",
    component: LandingPage,
    meta: {
      title: "Shipd - Seamless",
    },
  },
  {
    path: Routes.ENTERPRISE,
    name: "enterprise",
    component: EnterprisePage,
    meta: {
      title: "Enterprise Solutions - Shipd",
    },
  },
  {
    path: Routes.TOS,
    name: "tos",
    component: TosPage,
    meta: {
      title: "Terms of service - Shipd",
    },
  },
  {
    path: Routes.PRIVACY,
    name: "privacy",
    component: PrivacyPage,
    meta: {
      title: "Privacy policy - Shipd",
    },
  },
  {
    path: Routes.COOKIES,
    name: "cookies",
    component: CookiesPage,
    meta: {
      title: "Cookies - Shipd",
    },
  },
  {
    path: Routes.RELEASENOTES,
    name: "releasenotes",
    component: ReleaseNotesPage,
    meta: {
      title: "Release Notes - Shipd",
    },
  },
  {
    path: Routes.SIGNIN,
    name: "signin",
    component: SignInPage,
    meta: {
      title: "The Journey Continues - Shipd",
    },
  },
  {
    path: Routes.FORGOTPASSWORD,
    name: "forgotpassword",
    component: ForgotPassword,
    meta: {
      title: "The Adventure Begins - Shipd",
    },
  },
  {
    path: Routes.SIGNUP,
    name: "signup",
    component: SignUpPage,
    meta: {
      title: "The Adventure Begins - Shipd",
    },
  },
  {
    path: Routes.SIGNUPWELCOME,
    name: "signupwelcome",
    component: SignUpWelcome,
    meta: {
      title: "Welcome to Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.SIGNUPTHISDOWNLOAD,
    name: "signupthis",
    component: SignUpThis,
    meta: {
      title: "Download for current device - Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.SIGNUPREMOTEDOWNLOAD,
    name: "signupremote",
    component: SignUpRemote,
    meta: {
      title: "Download for your remote device - Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.SIGNUPPLAN,
    name: "signupplan",
    component: SignUpPlan,
    meta: {
      title: "Choose a plan - Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.SIGNUPVERIFY,
    name: "signupverify",
    component: SignUpVerify,
    meta: {
      title: "Verify your email - Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.SIGNUPCOMPLETE,
    name: "signupcomplete",
    component: SignUpComplete,
    meta: {
      title: "We are all set, let's go! - Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.DOWNLOAD,
    name: "download",
    component: DownloadPage,
    meta: {
      title: "Download our clients - Shipd",
      requiresAuth: true,
    },
  },
  {
    path: Routes.USERACTION,
    name: "useraction",
    component: EmailActionHandler,
    meta: {
      title: "Account action - Shipd",
    },
  },
  {
    path: Routes.REDIRECT,
    name: "redirect",
    component: RedirectPage,
    meta: {
      title: "Redirecting... - Shipd",
    },
  },
  {
    path: Routes.LEARN_MORE,
    name: "learn-more",
    component: LearnMorePage,
    meta: {
      title: "Learn more - Shipd",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 Page not found - Shipd",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const nearestWithTitle: any = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta: any = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta: any = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el: any) => {
      el.parentNode.removeChild(el);
    }
  );

  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef: any) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  const user = auth.currentUser;
  const authRequired = to.matched.some((record) => record.meta.requiresAuth);

  if (authRequired) {
    if (user) {
      return next();
    }
    return next({ path: "/signin" });
  } else next();
});

export default router;
